<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-4">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require(`@/assets/images/logo-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              max-height="150px"
              max-width="200px"
              alt="Regent Renewables"
              class="ms-8"
              contain
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <h1 class="d-flex align-center justify-center">
            Welcome
          </h1>
          <p class="d-flex align-center justify-center">
            {{ actionText }} to Regent Renewables.
          </p>
        </v-card-text>

        <!-- authentication providers -->
        <v-card-text>
          <v-btn
            v-for="link in socialLinks"
            :key="link.icon"
            block
            large
            rounded
            outlined
            class="mb-4"
            color="primary"
            @click="$auth.loginWithRedirect({ connection: link.connection, screen_hint: action })"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
            <span class="ms-2">
              Continue with {{ link.provider }}
            </span>
          </v-btn>
        </v-card-text>

        <!-- disclaimer  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-1">
            By proceeding you agree to our
          </span>
          <a href="https://regentrenewables.com/terms-of-service" target="_blank">
            terms of service
          </a>
          <span class="mx-1">
            and
          </span>
          <a href="https://regentrenewables.com/privacy-policy" target="_blank">
            privacy policy
          </a>
          .
        </v-card-text>
      </v-card>
    </div>

    <!-- background image  -->
    <img
      class="auth-bg"
      :src="require('@/assets/images/background.jpg')"
    >
  </div>
</template>

<script>
import providers from './providers'

export default {
  data() {
    return {
      socialLinks: providers,
    }
  },
  computed: {
    action() {
      return this.$route.name === 'sign-up' ? 'signup' : 'login'
    },
    actionText() {
      return this.$route.name === 'sign-up' ? 'Sign up' : 'Log in'
    },
  },
  mounted() {
    if (this.$auth.isAuthenticated) {
      this.$store.commit('alertType', 'info')
      this.$store.commit('alertText', 'You are already signed in')
      this.$router.push('/')
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
